<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>Community Members</h2>
    <div class="uk-grid" v-if="! loading">
      <div class="uk-width-1-5@m" v-for="(user, i) in users" :key="`user-${i}`">
        <div class="uk-card uk-card-default uk-text-center">
          <div class="uk-card-media-top">
            <router-link
              :to="{ name: 'trader.users.show', params: { id: user.id } }"
            >
              <span :class="`fa fa-bullseye user-online-icon ${userIsOnline(user) ? 'online' : ''}`" :title="`${userIsOnline(user) ? 'Online' : 'Offline'}`"></span>
              <img :src="user.avatar" :alt="user.name" style="height: 150px; width: 150px; border-radius: 50%; margin: 5px" />
            </router-link>
          </div>
          <div class="uk-padding-small">
            <router-link
              :to="{ name: 'trader.users.show', params: { id: user.id } }"
              >
              <h5 class="uk-margin-remove">{{ user.name }}</h5>
              <p class="uk-margin-remove">{{ user.role }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid" v-if="loading">
      <div class="uk-width-1-1 uk-text-center" style="background: #fff; padding: 100px 0;">
        <div uk-spinner></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/trader/users").then((response) => {
        this.users = response.data.users;
        this.loading = false;
      });
    },

    userIsOnline(user) {
      return this.$store.getters.getOnlineUsers.filter(u => {
        return u.id == user.id
      }).length > 0
    }
  },
};
</script>

<style lang="scss" scoped>
.user-online-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #ed1c24;

  &.online {
    color: #03a753;
  }
}
</style>